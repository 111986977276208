import React, { useEffect, useRef, useState } from 'react';
import TvingIcon from '@img/tving-new-logo-red.svg'; // src/img 폴더에 있는 파일
import { useIsomorphicLayoutEffect } from 'react-use';
import Head from 'next/head';
import useProvisionStore from '@stores/useProvisionStore';
import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import getLogoTypePopupEventProperties from '@utils/amplitude/eventProperties/getLogoTypeEventProperties';
import { useRouter } from 'next/router';
import { getScreenName } from '@utils/items/utils';

type NavLogoProps = {
    logoImgUrl: string;
};

const NavLogo = ({ logoImgUrl }: NavLogoProps) => {
    const router = useRouter();
    const provisionResponse = useProvisionStore((state) => state.provisionResponse);
    const [isOnLoaded, setIsOnLoaded] = useState(false);

    const isSeasonLogo = provisionResponse.isSeasonLogo ?? false;

    const onErrorLogoImg = (e) => {
        e.target.style.width = 'min-w-[7.666rem]';
        e.target.style.height = 'min-h-[1.866rem] ';
        if (!isOnLoaded) {
            e.target.src = '/img/tving-new-logo-red.svg'; // public 폴더에 있는 파일 로컬 이미지가 크기 때문에 교체 필요
            e.target.classList.add('!object-contain');
            setIsOnLoaded(true);
        }
    };

    const onLoadImg = (e) => {
        setIsOnLoaded(true);
    };

    const handleClick = () => {
        const { pathname } = router;
        const screenName = getScreenName({ pathName: pathname });

        amplitudeEvent({
            event: 'click_logo_pc',
            eventProperties: getLogoTypePopupEventProperties({
                screenName,
                isEvent: isSeasonLogo,
            }),
        });
    };

    const [logoUrl, setLogoUrl] = useState('');
    useIsomorphicLayoutEffect(() => {
        if (logoUrl || !logoImgUrl) {
            return;
        }
        setLogoUrl(logoImgUrl);
    }, []);

    return (
        <div className="flex items-center sm:w-[8.2rem] md:w-[10.5625rem] h-[4.333rem]">
            {logoImgUrl && (
                <Head>
                    <link rel="preload" as="image" href={logoImgUrl} />
                </Head>
            )}
            {logoImgUrl ? (
                logoUrl && (
                    <img
                        src={logoUrl}
                        onError={onErrorLogoImg}
                        onLoad={onLoadImg}
                        className="w-full sm:h-[2rem] md:h-full object-cover object"
                        alt="로고"
                        onClick={handleClick}
                    />
                )
            ) : (
                <div className="w-full lg:pl-[1.417rem] lg:pr-[1.417rem]" onClick={handleClick}>
                    <TvingIcon />
                </div>
            )}
        </div>
    );
};

export default React.memo(NavLogo);

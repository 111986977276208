type LogoTypePopupEventType = {
    screenName: string;
    isEvent: boolean;
};

/**
 * 메인 로고 클릭 시, 일반 로고인지 이벤트성 로고인지 데이터를 수집합니다.
 */
const getLogoTypePopupEventProperties = ({ screenName, isEvent }: LogoTypePopupEventType) => {
    return {
        screen_name: screenName,
        event_logo_yn: isEvent ? 'Y' : 'N',
    } as const;
};
export default getLogoTypePopupEventProperties;
